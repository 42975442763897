import React from 'react';

const ChemistryIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M10.8334 1.66667H14.1667V6.66667H10.8334V1.66667Z" fill="currentColor" />
        <path
            opacity="0.4"
            d="M10.8334 6.66667H14.1667L16.8862 12.1056C17.5511 13.4354 16.5841 15 15.0973 15H9.90282C8.41605 15 7.44906 13.4354 8.11396 12.1056L10.8334 6.66667Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 1.66667C10 1.32149 10.2798 1.04167 10.625 1.04167H14.375C14.7202 1.04167 15 1.32149 15 1.66667C15 2.01184 14.7202 2.29167 14.375 2.29167H10.625C10.2798 2.29167 10 2.01184 10 1.66667Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 1.04167C5.34518 1.04167 5.625 1.32149 5.625 1.66667L5.625 18.3333C5.625 18.6785 5.34518 18.9583 5 18.9583C4.65482 18.9583 4.375 18.6785 4.375 18.3333L4.375 1.66667C4.375 1.32149 4.65482 1.04167 5 1.04167Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.70837 6.66667C2.70837 6.32149 2.9882 6.04167 3.33337 6.04167L16.6667 6.04167C17.0119 6.04167 17.2917 6.32149 17.2917 6.66667C17.2917 7.01184 17.0119 7.29167 16.6667 7.29167L3.33337 7.29167C2.9882 7.29167 2.70837 7.01184 2.70837 6.66667Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.70837 18.3333C2.70837 17.9882 2.9882 17.7083 3.33337 17.7083L16.6667 17.7083C17.0119 17.7083 17.2917 17.9882 17.2917 18.3333C17.2917 18.6785 17.0119 18.9583 16.6667 18.9583L3.33337 18.9583C2.9882 18.9583 2.70837 18.6785 2.70837 18.3333Z"
            fill="currentColor"
        />
    </svg>
);

export default ChemistryIcon;
