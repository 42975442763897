import React from 'react';

const BottleIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.4"
            d="M11.6666 1.66667H8.33329L8.33329 6.90824C5.92372 7.62536 4.16663 9.85749 4.16663 12.5C4.16663 15.7217 6.7783 18.3333 9.99996 18.3333C13.2216 18.3333 15.8333 15.7217 15.8333 12.5C15.8333 9.85749 14.0762 7.62536 11.6666 6.90824V1.66667Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.875 1.66667C6.875 1.32149 7.15482 1.04167 7.5 1.04167H12.5C12.8452 1.04167 13.125 1.32149 13.125 1.66667C13.125 2.01185 12.8452 2.29167 12.5 2.29167H7.5C7.15482 2.29167 6.875 2.01185 6.875 1.66667Z"
            fill="currentColor"
        />
        <path
            d="M9.99996 18.3333C13.2216 18.3333 15.8333 15.7217 15.8333 12.5C15.8333 11.9209 15.7489 11.3614 15.5917 10.8333H4.40819C4.25102 11.3614 4.16663 11.9209 4.16663 12.5C4.16663 15.7217 6.7783 18.3333 9.99996 18.3333Z"
            fill="currentColor"
        />
    </svg>
);

export default BottleIcon;
