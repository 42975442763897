import React from 'react';

const BookIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.43065 4.52037C7.6609 3.28027 5.17721 2.71829 3.32857 2.50939C2.41365 2.40601 1.66663 3.17002 1.66663 4.11265V13.5C1.66663 14.4426 2.41365 15.2069 3.32857 15.3103C5.17721 15.5192 7.6609 16.0811 9.43065 17.3212C9.60071 17.4404 9.80033 17.5 9.99996 17.5V4.69913C9.80034 4.69913 9.60071 4.63954 9.43065 4.52037ZM3.54908 6.7833C3.60191 6.44219 3.92126 6.20849 4.26237 6.26132C5.34801 6.42945 6.57064 6.71048 7.72547 7.15705C8.04741 7.28155 8.20748 7.64346 8.08298 7.96541C7.95848 8.28735 7.59657 8.44741 7.27463 8.32292C6.22576 7.91732 5.09504 7.65517 4.07106 7.49659C3.72995 7.44376 3.49625 7.12441 3.54908 6.7833ZM4.26237 9.59465C3.92126 9.54182 3.60191 9.77552 3.54908 10.1166C3.49625 10.4577 3.72995 10.7771 4.07106 10.8299C4.5871 10.9098 5.13183 11.0163 5.68049 11.1547C6.01518 11.2392 6.35495 11.0363 6.43939 10.7016C6.52383 10.3669 6.32097 10.0272 5.98628 9.94271C5.39544 9.79364 4.81214 9.67979 4.26237 9.59465Z"
            fill="currentColor"
        />
        <path
            opacity="0.4"
            d="M10.5693 4.52037C12.3391 3.28027 14.8227 2.71829 16.6714 2.50939C17.5863 2.40601 18.3333 3.17002 18.3333 4.11265V13.5C18.3333 14.4426 17.5863 15.2069 16.6714 15.3103C14.8227 15.5192 12.3391 16.0811 10.5693 17.3212C10.3992 17.4404 10.1996 17.5 10 17.5V4.69913C10.1996 4.69913 10.3992 4.63954 10.5693 4.52037Z"
            fill="currentColor"
        />
    </svg>
);

export default BookIcon;
