import React from 'react';

const HomeIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.4"
            d="M2.07876 8.92413C1.43718 8.19412 1.55936 7.07142 2.34295 6.49636L8.04883 2.30895C9.21685 1.45177 10.8066 1.45264 11.9737 2.31109L17.6584 6.49253C18.4351 7.06379 18.5614 8.17472 17.9329 8.90568L17.5435 9.35855C17.1561 9.8091 16.9431 10.3835 16.9431 10.9776V15.0213C16.9431 16.8505 15.4596 18.3333 13.6296 18.3333H6.39448C4.5645 18.3333 3.081 16.8505 3.081 15.0213V11.0006C3.081 10.3973 2.8613 9.81453 2.46292 9.36124L2.07876 8.92413Z"
            fill="currentColor"
        />
        <circle cx="10" cy="13.3333" r="2.5" fill="currentColor" />
    </svg>
);

export default HomeIcon;
