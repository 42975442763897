import React from 'react';

const FlashIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.5"
            d="M4.3768 9.88036L10.2083 2.06335C10.7828 1.2933 11.9609 1.71945 11.9609 2.69729V7.4285C11.9609 7.99658 12.3999 8.4571 12.9414 8.4571H14.851C15.6683 8.4571 16.1268 9.44444 15.6231 10.1196L9.79161 17.9366C9.21715 18.7067 8.03898 18.2805 8.03898 17.3027V12.5715C8.03898 12.0034 7.6 11.5429 7.05849 11.5429H5.14894C4.33166 11.5429 3.8731 10.5556 4.3768 9.88036Z"
            fill="currentColor"
        />
    </svg>
);

export default FlashIcon;
