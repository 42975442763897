import React from 'react';

const WarningIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="17" r="1" fill="currentColor" />
        <path
            d="M2.29587 17.6324L1.64421 17.2612L2.29587 17.6324ZM21.7041 17.6324L21.0525 18.0037L21.7041 17.6324ZM14.032 4.16592L14.6837 3.79466L14.032 4.16592ZM9.96798 4.16592L10.6196 4.53719L9.96798 4.16592ZM12.7499 8.99997C12.7499 8.58576 12.4142 8.24997 11.9999 8.24997C11.5857 8.24997 11.2499 8.58576 11.2499 8.99997H12.7499ZM11.2499 14C11.2499 14.4142 11.5857 14.75 11.9999 14.75C12.4142 14.75 12.7499 14.4142 12.7499 14H11.2499ZM13.3804 4.53718L21.0525 18.0037L22.3558 17.2611L14.6837 3.79466L13.3804 4.53718ZM19.6721 20.25H4.32789V21.75H19.6721V20.25ZM2.94753 18.0037L10.6196 4.53719L9.31632 3.79466L1.64421 17.2612L2.94753 18.0037ZM4.32789 20.25C3.09801 20.25 2.38774 18.9862 2.94753 18.0037L1.64421 17.2612C0.482464 19.3003 2.01291 21.75 4.32789 21.75V20.25ZM21.0525 18.0037C21.6123 18.9862 20.902 20.25 19.6721 20.25V21.75C21.9871 21.75 23.5175 19.3003 22.3558 17.2611L21.0525 18.0037ZM14.6837 3.79466C13.5103 1.73511 10.4897 1.73512 9.31632 3.79466L10.6196 4.53719C11.2176 3.4876 12.7824 3.48761 13.3804 4.53718L14.6837 3.79466ZM11.2499 8.99997V14H12.7499V8.99997H11.2499Z"
            fill="currentColor"
        />
    </svg>
);

export default WarningIcon;
