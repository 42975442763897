import React from 'react';

const BoxIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.4"
            d="M15.5205 4.12021L11.3538 2.26836C10.4919 1.8853 9.50809 1.8853 8.6462 2.26836L4.47954 4.12021C3.27578 4.65521 2.5 5.84895 2.5 7.16625V12.8338C2.5 14.1511 3.27578 15.3448 4.47954 15.8798L8.6462 17.7317C9.50809 18.1147 10.4919 18.1147 11.3538 17.7317L15.5205 15.8798C16.7242 15.3448 17.5 14.1511 17.5 12.8338V7.16625C17.5 5.84895 16.7242 4.65521 15.5205 4.12021Z"
            fill="currentColor"
        />
        <path
            d="M15.5206 4.12035L11.3539 2.2685C10.492 1.88544 9.50818 1.88544 8.64629 2.2685L4.47963 4.12035C3.93288 4.36335 3.47442 4.74225 3.13684 5.2069L10.0001 8.33349L16.8633 5.2069C16.5258 4.74225 16.0673 4.36335 15.5206 4.12035Z"
            fill="currentColor"
        />
        <path
            d="M10 6.04167C10.3452 6.04167 10.625 6.32149 10.625 6.66667L10.625 17.9598C10.2121 18.0386 9.78788 18.0386 9.375 17.9598V6.66667C9.375 6.32149 9.65482 6.04167 10 6.04167Z"
            fill="currentColor"
        />
    </svg>
);

export default BoxIcon;
