import React from 'react';

const TwitterIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.65061 11.6665C1.2725 11.6704 0.382907 13.2403 1.23191 14.4298C2.44776 16.133 4.65255 17.5 8.3334 17.5C14.0376 17.5 18.6165 12.8514 17.9755 7.35925L18.9146 5.48108C19.5658 4.17857 18.4341 2.69685 17.0061 2.98245L15.7655 3.23057C15.4366 3.05702 15.0961 2.92606 14.8052 2.83117C14.2382 2.64627 13.552 2.5 12.9167 2.5C11.7756 2.5 10.7908 2.79276 10.0081 3.37994C9.2344 3.96053 8.79856 4.72354 8.5594 5.42421C8.44915 5.74717 8.37573 6.07112 8.32862 6.38202C7.8859 6.24269 7.43447 6.05522 6.99252 5.82629C5.98986 5.30693 5.17906 4.64852 4.72169 4.07126C3.95433 3.10275 2.32813 3.17456 1.75748 4.43351C0.953197 6.20789 1.17382 8.31122 1.89677 10.0108C2.13909 10.5803 2.4543 11.1434 2.83939 11.6652C2.77346 11.666 2.7104 11.6663 2.65061 11.6665ZM8.33331 15.8333C5.11386 15.8333 3.44914 14.6672 2.58835 13.4614C2.54967 13.4072 2.58867 13.3333 2.65523 13.3332C3.53093 13.3307 5.32843 13.2892 6.51393 12.5954C6.57524 12.5595 6.56175 12.4691 6.49441 12.4465C3.73094 11.5173 2.16008 7.58216 3.2754 5.12158C3.30097 5.06517 3.37681 5.05773 3.41528 5.10628C4.68934 6.71432 7.47501 8.28933 9.89865 8.33242C9.95123 8.33333 9.99098 8.28558 9.98298 8.23361C9.88531 7.60044 9.5124 4.16667 12.9166 4.16667C13.7296 4.16667 14.9391 4.56319 15.3846 4.96942C15.4051 4.98819 15.4331 4.99672 15.4604 4.99125L17.3329 4.61675C17.4009 4.60315 17.4548 4.67371 17.4238 4.73573L16.2624 7.05849C16.2543 7.07462 16.2518 7.09312 16.2549 7.11088C17.0683 11.6833 13.3233 15.8333 8.33331 15.8333Z"
            fill="currentColor"
        />
    </svg>
);

export default TwitterIcon;
