import React from 'react';

const NotesIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3334 1.04167C13.6786 1.04167 13.9584 1.32149 13.9584 1.66667V4.16667C13.9584 4.51185 13.6786 4.79167 13.3334 4.79167C12.9882 4.79167 12.7084 4.51185 12.7084 4.16667V1.66667C12.7084 1.32149 12.9882 1.04167 13.3334 1.04167Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66663 1.04167C7.0118 1.04167 7.29163 1.32149 7.29163 1.66667V4.16667C7.29163 4.51185 7.0118 4.79167 6.66663 4.79167C6.32145 4.79167 6.04163 4.51185 6.04163 4.16667V1.66667C6.04163 1.32149 6.32145 1.04167 6.66663 1.04167Z"
            fill="currentColor"
        />
        <path
            opacity="0.4"
            d="M2.5 6.25C2.5 4.40905 3.99238 2.91667 5.83333 2.91667H14.1667C16.0076 2.91667 17.5 4.40905 17.5 6.25V15C17.5 16.841 16.0076 18.3333 14.1667 18.3333H5.83333C3.99238 18.3333 2.5 16.841 2.5 15V6.25Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7916 10.8333C14.7916 11.1785 14.5118 11.4583 14.1666 11.4583H9.16663C8.82145 11.4583 8.54163 11.1785 8.54163 10.8333C8.54163 10.4882 8.82145 10.2083 9.16663 10.2083H14.1666C14.5118 10.2083 14.7916 10.4882 14.7916 10.8333Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7916 7.5C14.7916 7.84518 14.5118 8.125 14.1666 8.125H9.16663C8.82145 8.125 8.54163 7.84518 8.54163 7.5C8.54163 7.15482 8.82145 6.875 9.16663 6.875H14.1666C14.5118 6.875 14.7916 7.15482 14.7916 7.5Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7916 14.1667C14.7916 14.5118 14.5118 14.7917 14.1666 14.7917H9.16663C8.82145 14.7917 8.54163 14.5118 8.54163 14.1667C8.54163 13.8215 8.82145 13.5417 9.16663 13.5417H14.1666C14.5118 13.5417 14.7916 13.8215 14.7916 14.1667Z"
            fill="currentColor"
        />
        <path
            d="M7.08329 7.5C7.08329 7.96024 6.7102 8.33333 6.24996 8.33333C5.78972 8.33333 5.41663 7.96024 5.41663 7.5C5.41663 7.03976 5.78972 6.66667 6.24996 6.66667C6.7102 6.66667 7.08329 7.03976 7.08329 7.5Z"
            fill="currentColor"
        />
        <path
            d="M7.08329 10.8333C7.08329 11.2936 6.7102 11.6667 6.24996 11.6667C5.78972 11.6667 5.41663 11.2936 5.41663 10.8333C5.41663 10.3731 5.78972 10 6.24996 10C6.7102 10 7.08329 10.3731 7.08329 10.8333Z"
            fill="currentColor"
        />
        <path
            d="M7.08329 14.1667C7.08329 14.6269 6.7102 15 6.24996 15C5.78972 15 5.41663 14.6269 5.41663 14.1667C5.41663 13.7064 5.78972 13.3333 6.24996 13.3333C6.7102 13.3333 7.08329 13.7064 7.08329 14.1667Z"
            fill="currentColor"
        />
    </svg>
);

export default NotesIcon;
