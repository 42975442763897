import React from 'react';

const PaintBoardIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.4"
            d="M1.66663 9.99999C1.66663 13.9195 4.37264 17.2071 8.01838 18.0963C9.09149 18.358 9.99996 17.4379 9.99996 16.3333V13.7037C9.99996 12.6809 10.8291 11.8518 11.8518 11.8518C12.8746 11.8518 13.7037 12.6809 13.7037 13.7037V14.2398C13.7037 15.7245 15.2653 16.627 16.2481 15.5143C17.5458 14.045 18.3333 12.1144 18.3333 9.99999C18.3333 5.39762 14.6023 1.66666 9.99996 1.66666C5.39759 1.66666 1.66663 5.39762 1.66663 9.99999Z"
            fill="currentColor"
        />
        <path
            d="M9.16663 6.25C9.16663 5.55964 8.60698 5 7.91663 5C7.22627 5 6.66663 5.55964 6.66663 6.25C6.66663 6.94036 7.22627 7.5 7.91663 7.5C8.60698 7.5 9.16663 6.94036 9.16663 6.25Z"
            fill="currentColor"
        />
        <path
            d="M15.4166 8.33331C15.4166 7.64296 14.857 7.08331 14.1666 7.08331C13.4763 7.08331 12.9166 7.64296 12.9166 8.33331C12.9166 9.02367 13.4763 9.58331 14.1666 9.58331C14.857 9.58331 15.4166 9.02367 15.4166 8.33331Z"
            fill="currentColor"
        />
        <path
            d="M7.91663 12.9167C7.91663 12.2263 7.35698 11.6667 6.66663 11.6667C5.97627 11.6667 5.41663 12.2263 5.41663 12.9167C5.41663 13.607 5.97627 14.1667 6.66663 14.1667C7.35698 14.1667 7.91663 13.607 7.91663 12.9167Z"
            fill="currentColor"
        />
    </svg>
);

export default PaintBoardIcon;
