import React from 'react';

const HashIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.75 17C8.75 17.4142 9.08579 17.75 9.5 17.75C9.91421 17.75 10.25 17.4142 10.25 17H8.75ZM10.25 7C10.25 6.58579 9.91421 6.25 9.5 6.25C9.08579 6.25 8.75 6.58579 8.75 7H10.25ZM17 10.25C17.4142 10.25 17.75 9.91421 17.75 9.5C17.75 9.08579 17.4142 8.75 17 8.75V10.25ZM7 8.75C6.58579 8.75 6.25 9.08579 6.25 9.5C6.25 9.91421 6.58579 10.25 7 10.25V8.75ZM13.75 17C13.75 17.4142 14.0858 17.75 14.5 17.75C14.9142 17.75 15.25 17.4142 15.25 17H13.75ZM15.25 7C15.25 6.58579 14.9142 6.25 14.5 6.25C14.0858 6.25 13.75 6.58579 13.75 7H15.25ZM17 15.25C17.4142 15.25 17.75 14.9142 17.75 14.5C17.75 14.0858 17.4142 13.75 17 13.75V15.25ZM7 13.75C6.58579 13.75 6.25 14.0858 6.25 14.5C6.25 14.9142 6.58579 15.25 7 15.25V13.75ZM10.25 17V7H8.75V17H10.25ZM17 8.75H7V10.25H17V8.75ZM15.25 17V7H13.75V17H15.25ZM17 13.75H7V15.25H17V13.75Z"
            fill="currentColor"
        />
    </svg>
);

export default HashIcon;
