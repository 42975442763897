import React from 'react';

const CrossIcon: React.FC = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.5356 5.87865C11.9261 5.48813 11.9261 4.85496 11.5356 4.46444C11.1451 4.07391 10.5119 4.07391 10.1214 4.46444L11.5356 5.87865ZM4.46452 10.1213C4.074 10.5118 4.074 11.145 4.46452 11.5355C4.85505 11.926 5.48821 11.926 5.87874 11.5355L4.46452 10.1213ZM10.1214 11.5355C10.5119 11.926 11.1451 11.926 11.5356 11.5355C11.9261 11.1449 11.9261 10.5118 11.5356 10.1213L10.1214 11.5355ZM5.87874 4.4644C5.48821 4.07388 4.85505 4.07388 4.46452 4.4644C4.074 4.85493 4.074 5.48809 4.46452 5.87862L5.87874 4.4644ZM10.1214 4.46444L4.46452 10.1213L5.87874 11.5355L11.5356 5.87865L10.1214 4.46444ZM11.5356 10.1213L5.87874 4.4644L4.46452 5.87862L10.1214 11.5355L11.5356 10.1213Z"
            fill="currentColor"
        />
    </svg>
);

export default CrossIcon;
