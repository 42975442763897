import React from 'react';

const CopyIcon: React.FC = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 2L7 1.25H7V2ZM10 2L10 2.75H10V2ZM7 11.25H4V12.75H7V11.25ZM2.75 10V7H1.25V10H2.75ZM4 11.25C3.30964 11.25 2.75 10.6904 2.75 10H1.25C1.25 11.5188 2.48122 12.75 4 12.75V11.25ZM8.25 10C8.25 10.6904 7.69036 11.25 7 11.25V12.75C8.51878 12.75 9.75 11.5188 9.75 10H8.25ZM7 5.75C7.69036 5.75 8.25 6.30964 8.25 7H9.75C9.75 5.48122 8.51878 4.25 7 4.25V5.75ZM4 4.25C2.48122 4.25 1.25 5.48122 1.25 7H2.75C2.75 6.30964 3.30964 5.75 4 5.75V4.25ZM7 2.75L10 2.75L10 1.25L7 1.25L7 2.75ZM11.25 4V7H12.75V4H11.25ZM11.25 7C11.25 7.69036 10.6904 8.25 10 8.25V9.75C11.5188 9.75 12.75 8.51878 12.75 7H11.25ZM10 2.75C10.6904 2.75 11.25 3.30964 11.25 4H12.75C12.75 2.48122 11.5188 1.25 10 1.25V2.75ZM7 1.25C5.48122 1.25 4.25 2.48122 4.25 4H5.75C5.75 3.30964 6.30964 2.75 7 2.75V1.25ZM5.75 5V4H4.25V5H5.75ZM4 5.75H5V4.25H4V5.75ZM5 5.75H7V4.25H5V5.75ZM10 8.25H9V9.75H10V8.25ZM8.25 7V9H9.75V7H8.25ZM8.25 9V10H9.75V9H8.25Z"
            fill="currentColor"
        />
    </svg>
);

export default CopyIcon;
